import { useEffect, useState } from 'react';
import './App.css';

async function fetchCount() {
  const response = await fetch('https://api.twitter.com/2/tweets/counts/recent?query=مهسا_امینی&start_time=2022-09-14T00:00:00Z', {
    headers: {
      Authorization: 'Bearer AAAAAAAAAAAAAAAAAAAAAPU5hQEAAAAAlCW8WwabcVeU%2FhwpXd9vgZjekXI%3DpYERYXPWMlKFqOXM5TycWFaoubX2hofo01bjDi8qnT8A0gQZU0'
    }
  })
  const data = await response.json()

  return data
}

function App() {

  const [count] = useState(0)

  useEffect(() => {
    (async () => {
      const data = await fetchCount()
      console.log({ data });
    })()
  }, [])


  return (
    <div className="App">
      <h1>MAHSA</h1>
        <div>{count}</div>
    </div>
  );
}

export default App;
